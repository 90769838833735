<template>
  <div class="index-page">
    <hero></hero>
  </div>
</template>

<script>
import Hero from "./components/Hero";

export default {
  name: "components",
  components: {
    Hero
  }
};
</script>
